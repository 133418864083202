<template>
    <footer class="footer">
        <div class="sitemap" v-if="this.footerItems">
            <div class="container">

                <div class="logo">
                    <img src="@/assets/main-logo-footer.png" alt="logo" height="100" width="auto" class="logo-desktop">
                </div>

                <div class="inner-menu">
                    <div class="columns">
                        <div class="footer-column" v-for="(column, index) in this.footerItems" :key="'footer'+index">
                            <keep-alive v-for="(page, i) in column" :key="'page'+i">
                                <router-link v-if="!page.children" :to="page.url">{{ page.label }}
                                </router-link>
                                <div v-else>
                                    <h3>{{ page.label }}</h3>
                                    <router-link v-for="(child, ii) in page.children" :key="'child'+ii" :to="child.url">
                                        {{ child.label }}</router-link>
                                </div>
                            </keep-alive>
                        </div>
                    </div>

                    <div class="location">
                        <h4>San Canzian d’Isonzo tra storia e turismo</h4>
                        <p>
                            Largo G. Garibaldi 37 - Pieris<br>
                            34075 San Canzian d'Isonzo (GO) <br>
                            Tel. 0481 472 311 | Fax 0481 472 334
                        </p>
                    </div>
                </div>

            </div>
        </div>

        <div class="contacts">
            <div class="container">

                <div class="infos">
                    <p>Comune di San Canzian d’Isonzo - P.IVA 00123510315 - C.F. 81001530310</p>
                    <p>
                        <a href="http://www.comune.sancanziandisonzo.go.it">www.comune.sancanziandisonzo.go.it</a>
                        - <a mailto="segreteria@comune.sancanziandisonzo.go.it">
                            segreteria@comune.sancanziandisonzo.go.it</a>
                    </p>
                </div>

                <div class="policy">
                    <ul>
                        <li class="social">
                            <div>
                                <a target="_blank" class="fb" href="https://www.facebook.com/comunesancanzian/">
                                    <img src="../../assets/fb_icon-w-01.svg" width="30" alt="">
                                </a>
                                <a target="_blank" class="in"
                                    href="https://www.instagram.com/explore/locations/104808913/comune-di-san-canzian-disonzo/?hl=it">
                                    <img src="../../assets/in_icon-w-01.svg" width="30" alt="">
                                </a>
                            </div>
                        </li>
                        <li>
                            <a href="http://www.comune.sancanziandisonzo.go.it/html/italiano/amministrazione_trasparente/privacy.html"
                                target="_blank">Privacy Policy</a>
                        </li>
                        <li>
                            <router-link to="/cookie-policy">Cookie Policy</router-link>
                        </li>
                        <li>
                            <router-link to="/credits">Credits</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'the-footer',
        data: () => {
            return {
                columnLength: 2,
                footerItems: []
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            }
        },
        methods: {
            async fetchFooterItems() {
                var menu = []
                var items = []
                var ch = []

                var l = this.lang != this.defaultLang ? ('/' + this.lang) : ''

                await fetch(`${process.env.VUE_APP_ENDPOINT}${l}/jconfig/menu/main`)
                    .then(res => res.json())
                    .then(json => {
                        items = json.menu.sort((a, b) => {
                            return a.weight > b.weight ? -1 : 1
                        })
                    })

                //for each fetched route
                items.forEach((item, index) => {
                    ch = []

                    //for each menu item with no parent
                    //if the number of its children is 0
                    if (!item.has_children) {
                        //push it into the menu as a top-level route
                        if (menu[Math.floor(index / this.columnLength)] == undefined)
                            menu[Math.floor(index / this.columnLength)] = []

                        menu[Math.floor(index / this.columnLength)].push({
                            label: item.title,
                            url: item.url
                        })

                    } else {
                        item.subtree.forEach(child => ch.push({
                            label: child.title,
                            url: child.url
                        }))

                        //then push them into the menu as a sub-level route (for that corresponding top-level menu)
                        if (menu[Math.floor(index / this.columnLength)] == undefined)
                            menu[Math.floor(index / this.columnLength)] = []

                        menu[Math.floor(index / this.columnLength)].push({
                            label: item.attributes.title,
                            children: ch
                        })
                    }
                })
                this.footerItems = menu
            }
        },
        mounted() {
            this.fetchFooterItems()
        },
        watch: {
            lang() {
                this.fetchFooterItems()
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../styles/variables.scss";

    .social {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 13px;

        a {
            display: inline-block;
            margin-right: 10px;
        }
    }

    footer {

        .container {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
        }

        .logo {
            margin-bottom: 30px;

            img {
                width: 100%;
                height: auto;
            }
        }


        .sitemap {
            background-color: #181923;
            padding: 1rem 0rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .inner-menu {
                margin: 0%;
            }

            .footer-column {
                display: none;
            }

            h4 {
                color: #fff;
                font-weight: 700;
                margin: 0 0 1rem 0;
            }

            a {
                color: #C9CFD9;
                padding: 6px 0px;
                font-size: 0.875rem;
                text-decoration: none;

                &:hover {
                    color: $primaryColor;
                }
            }

            p {
                color: #fff;
                font-size: 0.875rem;
            }
        }

        .contacts {
            background-color: #20212E;
            padding: 1rem 0rem;

            .container {
                display: flex;
                flex-direction: column;

                p {
                    color: #929298;
                    font-size: 0.875rem;
                    margin: .5em 0;
                }

                .infos {
                    margin-bottom: 0rem;
                    word-break: break-all;
                    flex-wrap: wrap;

                    p {
                        font-size: 12px;
                    }

                }

                .policy ul {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    li {
                        padding-left: 0;
                        padding-right: 0;

                        &:before {
                            display: none;
                        }

                        a {
                            text-decoration: none;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }



    @media (min-width: 576px) {
        footer {
            p {
                word-break: normal;
            }

            .sitemap .container {
                display: flex;
                flex-direction: column;

                .logo {
                    display: flex;
                    align-items: center;
                }
            }

            .contacts {
                .container {
                    display: flex;
                    justify-content: space-between;
                    align-items: start;

                    .infos {
                        width: 50%;
                    }

                    .policy {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        .social {
            display: flex;
            justify-content: flex-end;
        }

        footer {
            .container {
                width: 100%;
                flex-direction: row;
                max-width: 600px;
            }

            .contacts {
                .container {
                    flex-direction: row;
                }
            }
        }

        footer {
            .contacts {
                .policy {
                    width: 50%;

                    ul {
                        flex-direction: column;
                        align-items: flex-end;
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        footer {
            .container {
                width: 100%;
                flex-direction: row;
                max-width: 900px;
            }
        }
    }

    @media (min-width: 1024px) {
        .social {
            width: auto;
        }

        .location {
            padding-left: 2rem;
            border-left: 1px solid $primaryColor;
        }

        footer .sitemap .container {
            flex-direction: row-reverse;
        }

        footer {

            .sitemap {

                .inner-menu {
                    width: 60%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    position: relative;

                    .location {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        p {
                            margin: 0;
                        }
                    }

                    .columns {
                        width: 30%;
                    }
                }

                .logo {
                    width: 420px;
                }

                .footer-column {
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    a {
                        color: $primaryColor;
                        text-transform: uppercase;
                    }
                }
            }

            .contacts {
                .container {

                    .infos {
                        p {
                            margin-right: 2em;
                        }
                    }

                    .policy {
                        ul {
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;

                            li {
                                padding-left: 2rem;
                                padding-top: 0;

                                a {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1200px) {

        footer {
            .container {
                max-width: 1170px;
            }

            .sitemap {
                .inner-menu {
                    width: 100%;
                    justify-content: space-around;
                }
            }

            .contacts {
                .container {
                    .infos {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }

                    .policy {
                        width: 40%;

                        ul {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1500px) {
        .social {
            padding-bottom: 0;
            padding-top: 9px;
            margin-top: 0;
        }

        footer {
            .container {
                max-width: 1433px;
            }
        }

        footer {
            .sitemap {
                padding: 2rem 0;

                .inner-menu {
                    justify-content: center;


                    .columns {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                    }



                    .footer-column {
                        flex-direction: row;
                        width: 100%;

                        a {
                            font-size: 1rem;
                            min-width: 50%;
                        }
                    }
                }
            }
        }
    }
</style>