<template>
    <div class="main-container home-page">
        <div class="intro">
            <div class="container">
                <h1 :class="{ 'de': this.lang=='de' }" v-html="this.$store.state.labelData[this.lang].home.title"></h1>
                <p v-html="this.$store.state.labelData[this.lang].home.subtitle"></p>
            </div>
        </div>
        <div class="menu">
            <div @click="cosafare" class="cosa-fare" v-if="cosafareData.attributes">
                <picture>
                    <!-- <source srcset="../assets/home-cosa-fare.jpg" alt="cosa fare" media="(min-width:650px)" type="image/jpeg"> -->
                    <!-- <source srcset="../assets/home-cosa-fare_sm.jpg" alt="cosa fare"  type="image/jpg"> -->
                    <img src="../assets/home-person.png"> 
                </picture>  
                <div class="container space-between">
                    <div >
                        <h1>{{ cosafareData.attributes.title }}</h1>
                        <div v-html="cosafareData.field_ref_ui_text[0].attributes.field_text_with_summary.value"></div>
                    </div>
                    <div class="arrow"></div>
                </div>
            </div>

            <div @click="lastoria" class="la-storia" v-if="lastoriaData.attributes">
                <picture>
                    <!-- <source srcset="../assets/la-storia.jpg" alt="cosa fare" media="(min-width:650px)" type="image/jpeg"> -->
                    <!-- <source srcset="../assets/la-storia_sm.jpg" alt="cosa fare"  type="image/jpg"> -->
                    <img src="../assets/home-santo.png">
                </picture> 
                <div :class="['container space-between', this.lang=='de' ? 'de' : '']">
                    <div >
                        <h1>{{ lastoriaData.attributes.title }}</h1>
                        <div v-html="lastoriaData.field_ref_ui_text[0].attributes.field_text_with_summary.value"></div>
                    </div>
                    <div class="arrow"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchRoutes, fetchSinglePage } from '../libs/drupalClient'
//import { getTranslation } from '../libs/drupalClient'
export default {
    name: 'home-page',
    data: () => {
        return {
            lastoriaID: "c111f013-0c30-4372-b82a-df5445f1f435",
            cosafareID: "22a0e792-be9f-4862-b385-b663f544e0c7",
            lastoriaData: {},
            cosafareData: {},
            routesData: []
        }
    },
    metaInfo() {
      return {
        title: 'Scopri San Canzian D\'Isonzo',
        titleTemplate: '%s',
        meta: [
          {
            property: 'og:title',
            content: 'Scopri San Canzian D\'Isonzo'
          }
        ]
      }
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        }, 
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    methods: {
        cosafare() {
            this.$router.push(this.routesData.find(f => f.nid==this.cosafareData.attributes.drupal_internal__nid && this.lang==f.lang).path)
        },
        lastoria() {
            this.$router.push(this.routesData.find(f => f.nid==this.lastoriaData.attributes.drupal_internal__nid && this.lang==f.lang).path)
        }
    },
    mounted() {
        fetchRoutes()
        .then(r => this.routesData = r.routes)

        fetchSinglePage(this.lastoriaID, this.lang)
        .then(res => { this.lastoriaData = res })

        fetchSinglePage(this.cosafareID, this.lang)
        .then(res => this.cosafareData = res)
    },
    watch: {
        lang() {
            this.$router.push({
                path: '/' + (this.lang==this.defaultLang ? '' : this.lang)
            })

            fetchSinglePage(this.lastoriaID, this.lang)
            .then(res => this.lastoriaData = res)

            fetchSinglePage(this.cosafareID, this.lang)
            .then(res => this.cosafareData = res)
            }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
 
section {
    background: #ebebeb;
    margin: 2rem 0rem;
    padding: 2rem;
    h2 {
        margin: 2rem 0rem;
    }
    a {
        display: block;
        margin: 1rem 0rem;
    }
}

.intro {
    height: 100vh;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0), $secondaryColor), url("../assets/intro-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    margin-bottom: -6vh;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
    }
    h1 {
        color: #fff;
        font-size: 2.8125rem;
        text-transform: uppercase;
        margin-bottom: 0;
        //line-height: 3.2rem;
        line-height: 1.2em;
        span, ::v-deep span { color: $primaryColor; }   
    }
    p {
        color: #fff;
        width: 100%;
        font-size: 1.25rem;
        padding-bottom: 2rem;
        span, ::v-deep span { color: $primaryColor; }
    }
}


.home-page .intro h1.de { font-size: 3.25rem; }

.menu {
    height: 160vh;
    display: flex;
    flex-direction: column;
    &>div {
        height: 50%;
        color: #fff;
        ::v-deep p,
        p {
            color: #fff;
            line-height: 2rem;
        }
    }
    .la-storia,
    .cosa-fare {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transform: skewY(10deg);
        picture {
            position: absolute;
            z-index: 0;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            transform: skewY(-10deg) scale(1.4) translateY(9vh);
            height: 100%;
            img { 
                width: 100%; 
                object-fit: cover;
                height: 60%;
            }
        }

        h1 {
            font-size: 1.875rem;
            margin: 0;
            text-transform: uppercase;
        }
        
        .container {
            position: absolute;
            top: 10vh;
            z-index: 1;
            margin: 0;
            margin-bottom: 10vh;
            transform: skewY(-10deg);
            p, div ::v-deep p {
                margin-top: 0;
            }
            .arrow {
                width: 60px;
                height: 60px;
                background-image: url(../assets/arrow-icon-circle.svg);
                background-repeat: none;
                background-size: contain;
            }
        }
    }
    .cosa-fare {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: url("../assets/home-green-bg.jpg") ;
    }
    .la-storia {
        picture {
            transform: skewY(-10deg) scale(1.4) translateY(0);
        }
        .container {
            top: 6vh;
        }

        background: url("../assets/home-blue-bg.jpg") ;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

@media (min-width: 400px) {
    .menu {
        .cosa-fare,
        .la-storia {
            picture {
                transform: skewY(-10deg) scale(1.3) translateY(4vh);
                img {
                    height: 80%;
                }
            }
        }
        .la-storia {
            picture {
                transform: skewY(-10deg) scale(1.3) translateY(-5vh);
            }
        }
    }
}

@media (min-width: 576px) {
    .intro {
        margin-bottom: -10vh;
    }
    .menu {
        height: 190vh;
        .la-storia,
        .cosa-fare {
            .container {
                margin: auto;
                width: 100%;
                margin-bottom: 15vh;
                min-height: 250px;
                top: 15vh;
            }
            picture{
                //height: auto;
                position: relative;
                transform: skewY(-10deg) scale(1.3) translateY(9vh);
                img {
                    height: 100%;
                    width: 90%;
                    object-fit: contain;
                    margin-left: auto;
                }
            }
        }
        .la-storia {
            .container {
                top: 9vh;
            }
            picture {
                transform: skewY(-10deg) scale(1.3) translateY(0);
            }
        }
    }
}

@media (min-width: 630px) {
    .menu {
        .cosa-fare,
        .la-storia {
            picture {
                transform: skewY(-10deg) scale(1.3) translateY(6vh);
            }
        }

        .la-storia {
            picture {
                transform: skewY(-10deg) scale(1.3) translateY(-3vh);
            }
        }
    }
}

@media (min-width: 768px) {
    .intro {
        margin-bottom: -15vh;
    }
    .menu {
        .la-storia,
        .cosa-fare {
            .container {
                top: 22vh;
                left: 6vh;
            }
            picture {
                img {
                    width: 75%;
                    height: 80%;
                    left: -40%;

                }
            }
        }

        .la-storia {
            .container {
                top: 11vh;
            }
        }
    }
}

@media (min-width: 1024px) {
    .home-page {
        display: flex;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        .intro {
            z-index: 2;
            width: 50%;
            margin-left: -5%;
            margin-bottom: 0vh;
            transform: skewY(0deg) skewX(-6deg);

            .container {
                transform: skewY(0deg) skewX(6deg);
                padding-left: 10%;
                width: 70%;
            } 
            h1 {
                font-size: 3.75rem;
                margin-bottom: 0;
            } 
            p { margin-bottom: 6rem;  }

        }
        .menu {
            height: auto;
            display: flex;
            flex-direction: row;
            width: 60%;
            margin-right: -10%;

            > div { height: auto; }

            h1 {  font-size: 2.9375rem; } 
            
            .la-storia,
            .cosa-fare {
                z-index: 1;
                transform: skewY(0deg) skewX(-6deg);

                &:after {
                    display: block;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    //opacity: 0;
                    opacity: 1;
                    transition: all 300ms ease;
                }

                picture {
                    height: 100%;
                    transform: skewY(0deg) skewX(6deg) scale(1.5) translateX(-20%);
                    transition: all 300ms ease;
                    img {
                        position: absolute;
                        height: 100%;
                        width: auto;
                        left: 0%;

                    }
                }
                .container {
                    padding-left: 0;
                    //top: 70%;
                    top: unset;
                    bottom: 5vh;
                    margin-bottom: 0;
                    left: 4vh;
                    width: 80%;
                    transform: skewY(0deg) skewX(6deg);
                    transition: left 300ms ease;
                    .arrow {
                        transform: scale(1);
                        transition: background-color, transform 300ms ease;
                    }
                    &.de {
                        word-break: break-all;
                    }
                    h1 {
                        line-height: 1.2em;
                        transition: color 300ms ease;
                    }
                }

                &:hover {
                    /*&:after {
                        opacity: 1;
                        transition: all 300ms ease;
                    }*/
                    picture {
                        transform: skewY(0deg) skewX(6deg) scale(1.5) translateX(-25%);
                        transition: all 300ms ease;
                    }
                    .container {
                        //::v-deep p
                        left: 6vh;
                        transition: left 300ms ease;
                        h1 {
                            //color: $primaryColor;
                            transition: color 300ms ease;
                        }
                        .arrow { 
                            //background-image: url(../assets/arrow-icon-circle-y.svg); 
                            background-color: rgba(#fff, .2);
                            border-radius: 50%;
                            transition: background-color, transform 300ms ease;
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .la-storia { 
                width: 55%;
                position: relative;
                left: -4px;
                &:after {
                    background: linear-gradient(to top, rgba($secondaryColor, 1) 0%, rgba($secondaryColor, 0) 75%);
                }
            } 
            .cosa-fare { 
                width: 45%;
                position: relative;
                left: -2px;
                &:after {
                    //background-color: rgba($greenColor, .6);
                    background: linear-gradient(to top, rgba($greenColor, 1) 0%, rgba($greenColor, 0) 75%);
                    //background: linear-gradient(to right, rgba($greenColor, 1) 0%, rgba($greenColor, 0) 100%);
                }
            }
        }
    }

    .space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

/*@media (min-width: 1200px) {
    .home-page {
        .menu {
            .la-storia,
            .cosa-fare {
                picture {
                    transform: skewY(0deg) skewX(6deg) scale(1) translate(-150px);
                }

                &:hover {
                    picture {
                        transform: skewY(0deg) skewX(6deg) scale(1) translate(-180px);
                    }
                }
            }
        }
    }
}*/
</style>