<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from './components/layout-blocks/TheHeader'
import TheFooter from './components/layout-blocks/TheFooter'

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  data: () => {
    return {
      metas: {}
    }
  },
  metaInfo() {
    return {
      titleTemplate: 'San Canzian D\'Isonzo | %s',
      meta: [
        {
          name: 'og:description',
          content: this.metas.metadata ? this.metas.metadata.description : ''
        },
        {
          property: 'og:title',
          content: this.metas.metadata ? this.metas.metadata.title : ''
        },
        {
          property: 'og:site_name',
          content: 'San Canzian D\'Isonzo'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: this.metas.metadata ? this.metas.metadata.image : 'https://scoprisancanziandisonzo.it/social-image.jpg'
        },
        {
          property: 'twitter:url',
          content: 'https://scoprisancanziandisonzo.it' + this.$route.path
        },
        {
          property: 'facebook:url',
          content: 'https://scoprisancanziandisonzo.it' + this.$route.path
        },
        {
          name: 'robots',
          content: 'index,follow'
        }
      ]
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    route() {
      return this.$route
    }
  },
  methods: {
    refreshPage () {
      //evento chiamato al refresh della pagina o al reindirizzamento di una route
      //per evitare il cambio di lingua non voluto

      //aggiorna STORE e LOCALSTORAGE (come helper di sessione) simultaneamente
      localStorage.setItem('LS_LANG_KEY', this.$store.state.currentLang)
      this.$store.commit('SET_CURRENT_LANG', localStorage.getItem('LS_LANG_KEY'))
		},
    fetchMetas() {
      //if(this.$route.fullPath=='/') {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=/home')
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          //json.metadata.title = "Homepage"
          this.metas = json
        })
      /*} else {
        console.log('metas of', this.$route.fullPath)
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=' + this.$route.fullPath)
        .then(res => res.json())
        .then(json => {
          console.log('FETCH META', json)
          this.metas = json
        })
      }*/

      //console.log(this.metas)
    }
  },
  mounted() {
    this.refreshPage()
    this.fetchMetas()
  },
  watch: {
    route() {
      this.refreshPage()
      this.fetchMetas()
    }
  }
}
</script>

<style lang="scss">
@import "style.scss";
</style>
