<template>
  <header>
    <div class="container" v-if="this.pagedata">
      <h1 class="page-title">{{ this.pagedata.attributes.title }}</h1>
    </div>
  </header>
</template>
<script>
  export default {
    name: 'header-intro',
    props: {
      pagedata: Object
    }
  }
</script>
<style lang="scss" scoped>
  header {
    padding-top: 8rem;
    background: #36384A url("../assets/filigrana-header.png");
     background-position: left;
    transform: skewY(-5deg);
    position: relative;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.26);
    margin-top: -2rem;
    margin-bottom: 5rem;

    h1 {
      padding: 3rem 0rem;
      margin: 0;
      text-transform: uppercase;
      color: #fff;
      font-weight: 400;
      transform: skewY(5deg);
    }
  }
  
  @media (min-width:768px) {
    header {
      margin-top: -4rem;
      background-repeat: no-repeat;
      background-size: 800px;

      h1 {
        padding: 6rem 0rem 2rem;
      }
    }
  }

  @media (min-width:1400px) {
    header {
      margin-top: -6rem;
      padding-top: 12rem;
    }
  }
</style>