<template>
  <div class="main-container contatti">
    <header-intro :pagedata="pageData"></header-intro>
    <div class="main-content">

      <div class="container">
        <div class="content">
          <div class="title">
            <h2>404 - {{ this.$store.state.labelData[this.lang].not_found }}</h2>
            <p>{{ this.$store.state.labelData[this.lang]['404_error'] }}</p>
            <router-link class="btn" to="/">{{ this.$store.state.labelData[this.lang].home_button }}</router-link>
          </div>
          
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import HeaderIntro from '../components/HeaderIntro'
export default {
  name: 'not-found',
  components: {
    HeaderIntro,
  },
  data() {
    return {
      pageData: {
        attributes: {
          title: "Error"
        }
      },
      formData: {
        fullname: "",
        email: "",
        message: ""
      },
      currentPageID: ""
    }
  },
  metaInfo() {
    return {
      title: this.pagemetatitle,
      meta: [
        {
          property: 'og:title',
          content: this.pagemetatitle
        }
      ]
    }
  },
  computed: {
    pagemetatitle() {
      if(this.lang == 'it') return 'Non Trovato'
      else if(this.lang=='de') return 'Nicht Gefunden'
      else return 'Not Found'
    },
    lang() {
      return this.$store.state.currentLang
    }, 
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  watch: {
    lang() {
      this.$router.push(
        (this.lang==this.defaultLang ? '' : ('/' + this.lang ))
        + '/not-found')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.main-content {
  margin: 3rem 0 3rem 0;
}

figure {
  margin: 0;
  img { width: 100%; }
}
figcaption {
  font-size: 0.875rem;
  color: #8E8E8F;
}

.btn {
  text-decoration: none;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  h2 { margin-top: 3rem; }

  .content {
    display: flex;
    justify-content: space-between;
    &> div { width: 46%; }
  }
}

</style>