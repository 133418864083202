import Vue from "vue";
import Vuex from "vuex";
import labels from "../json/labels.json";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lastPage: '',
        currentLang: 'it',
        defaultLang: '',
        languages: [],
        pageExceptions: [],
        poiData: null,
        museumData: null,
        labelData: labels,
        selectedInsightID: null,
        selectedRepertoID: 0
    },
    mutations: {
        SET_CURRENT_LANG: function(state, payload) {
            state.currentLang = payload;
        },
        SET_LAST_PAGE: function(state, payload) {
            state.lastPage = payload;
        },
        SET_DEFAULT_LANG: function(state, payload) {
            state.defaultLang = payload;
        },
        SET_LANGUAGES: function(state, payload) {
            state.languages = payload;
        },
        SET_SELECTED_INSIGHT(state, payload) {
            state.selectedInsightID = payload;
        },
        SET_SELECTED_REPERTO(state, payload) {
            state.selectedRepertoID = payload;
        },
        SET_MUSEUMDATA(state, payload) {
            state.museumData = payload;
        },
        SET_POIDATA(state, payload) {
            state.poiData = payload;
        }
    },
    actions: {}
});