<template>
  <header class="navigation" v-on:scroll="scrollFunction()" :class="{sticky : windowScrolled}">
    <nav class="navbar">
      <div class="container">

        <router-link :to="lang==defaultLang ? '/' : '/'+lang" class="navbar-brand" @click.native="closeMenu">
          <img src="@/assets/main-logo.svg" alt="logo" height="100" width="auto" class="logo logo-desktop"/>
        </router-link>

        <div :class="['menu-list inner-container container', menuOpen ? 'show' : '']" >
          <!-- create a list of menu routes -->
          <ul class="navbar-nav">
            <!-- for every top level menu route ... -->
            <li v-for="(item, index) of menuItems" :key="'menu' + index"
              :class="['nav-item', index === submenuOpen ? 'active' : '']"
            >
              <!-- if has children is not clickable -->
              <div v-if="item.children && item.children.length > 0" class="nav-item-content" @click="openSubmenu(index)">
                <span class="nav-link dropdown">{{ item.label }}</span>
              </div>

              <!-- if it's a top level route menu, it is a link -->
              <div class="nav-item-content" v-else>
                <router-link class="nav-link" :to="item.url" @click.native="closeMenu">
                  {{ item.label }}
                </router-link>
              </div>

              <!-- if it has children, create a list of sub links -->
              <ul
                v-if="item.children && item.children.length > 0"
                :class="['submenu', submenuOpen === index ? 'active': '']"
                v-show="submenuVisible"
              >
                <li v-for="(child, index) of item.children" :key="index">
                  <!-- create a clickable link for every child -->
                  <div>
                    <router-link :to="child.url" @click.native="closeMenu">
                      {{ child.label }}
                    </router-link>
                  </div>
                </li>
              </ul>

            </li>

            <li :class="['languages', 'nav-item', this.$store.state.currentLang]">
              <div class="nav-item-content">
                <span class="nav-link dropdown">{{ this.$store.state.currentLang }}</span>
                <ul class="submenu" v-show="submenuVisible">
                  <li :class="['it', this.$store.state.currentLang=='it' ? 'active' : '']" @click.prevent="changeLang('it')">
                    <div>
                      <a href="#">IT</a>
                    </div>
                  </li>
                  <li :class="['en', this.$store.state.currentLang=='en' ? 'active' : '']" @click.prevent="changeLang('en')">
                    <div>
                      <a href="#">EN</a>
                    </div>
                  </li>
                  <li :class="['de', this.$store.state.currentLang=='de' ? 'active' : '']" @click.prevent="changeLang('de')">
                    <div>
                      <a href="#">DE</a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="navbar-toggler" >
          <label for="check">
            <input type="checkbox" id="check"  @click="toggleMenu"/> 
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'the-header',
  data() {
    return {
      windowScrolled: false,
      menuOpen: false,
      submenuOpen: null,
      submenuVisible: true,
      menuItems: []
    };
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {
    changeLang(l) {
      this.$store.commit('SET_CURRENT_LANG', l)
      this.closeMenu()
      document.getElementById('check').checked = false
    },
    async fetchMenuItems() {
      console.log('VUE_APP_ENDPOINT',process.env.VUE_APP_ENDPOINT);
      var menu = []
      var items = []
      var ch = []

      var l = this.lang!=this.defaultLang ? ('/' + this.lang) : ''
      
      await fetch(`${process.env.VUE_APP_ENDPOINT}${l}/jconfig/menu/main`)
        .then(res => res.json())
        .then(json => {
          items = json.menu.sort((a, b) => { return a.weight > b.weight ? -1 : 1 })
        })

      //for each fetched route
      items.forEach(item => {
        ch = []

        //for each menu item with no parent 
        //if the number of its children is 0
        if(!item.hasChildren) {
          //push it into the menu as a top-level route
          menu.push({
            label: item.title,
            url: item.url
          })

        } else {
          item.subtree.forEach(child => ch.push({
            label: child.title,
            url: child.url
          }))
          
          //then push them into the menu as a sub-level route (for that corresponding top-level menu)
          menu.push({
            label: item.attributes.title,
            children: ch
          })
        }
      })

      //create the menu
      this.menuItems = menu
    },
    toggleMenu() {
      if(this.menuOpen)
        this.closeMenu()
      else
        this.openMenu()
      
      this.openSubmenu(null)
    },
    openMenu() {
      document.body.classList.add('noscroll')
      //document.querySelector("html").classList.add("menu-open");
      document.body.classList.add("menu-open");
      document.getElementById('check').checked = true
      this.menuOpen = true;

      //console.log('open menu')
    },
    closeMenu() {
      document.body.classList.remove('noscroll')
      //document.querySelector("html").classList.remove("menu-open");
      document.body.classList.remove("menu-open");
      document.getElementById('check').checked = false
      this.openSubmenu(null)
      this.menuOpen = false

      //console.log('close menu')

      this.hideSubmenu()
    },
    hideSubmenu(){
      // hide temporary sub menu after click
      this.submenuVisible = false
      setTimeout(() => {
        this.submenuVisible = true
      }, 100)
    },
    //just one submenu can stay open at a time
    openSubmenu(index) {
      //console.log(index)
      if (this.submenuOpen === index)
        this.submenuOpen = null
      else
        this.submenuOpen = index
    },
    handleScroll() {
      if(window.pageYOffset)
        this.windowScrolled = true
      else
        this.windowScrolled = false
    },
    change(ev) {
      this.changeLang(ev.target.value)
    }
  },
  created() {
    this.fetchMenuItems();
    window.addEventListener('scroll', this.handleScroll);
  },
  watch: {
    lang() {
      this.fetchMenuItems();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

header {
  z-index: 6;
}

header.navigation {
  background-color: #0000006e;
  padding: 1rem 0rem;
}

header.navigation nav .navbar-toggler {
  background-color: $primaryColor;
  width: 60px; 
  height: 60px;
  label {
    width: 60px;
    display: flex;
    align-items: center;
    span {
      height: 5px;
      width: 100%;
    }
  }
}
</style>