import Vue from 'vue'
import Vuex from 'vuex'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";

import App from './App.vue'
import { router } from './router/index'
import store from './store/index'
//import { fetchRoutes } from './libs/drupalClient'

router().then(router => {

  Vue.use(VueMeta)  //vue module per i METATAG
  Vue.use(Vuex)     //vue module per lo STORE
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ANALYTICS_CODE }
  }, router);

  // window.popStateDetected = false
  // window.addEventListener('popstate', () => {
  //   window.popStateDetected = true
  // })

  // //MANAGING BACK BUTTON HISTORY
  // //navigazione multilingua per gestire 'indietro' dal browser senza cambiare lingua
  // //pagina1 -> pagina2 -> cambio lingua in pagina2 -> indietro -> pagina1 con nuova lingua
  // //non pagina2 con vecchia lingua == funzionamento originale di history VUE.js router (a causa di $router.push)
  // router.beforeEach((to, from, next) => {
  //   const IsItABackButton = window.popStateDetected
  //   window.popStateDetected = false
  //   console.log('************')
  //   console.log(from.name, '-->', to.name)

  //   if(IsItABackButton) {
  //     var last = localStorage.getItem('LAST_VISITED')
  //     var lastR = localStorage.getItem('LAST_REPERTO')
  //     console.log('here', last)
  //     var url = null

  //     if(last && last=="details/Item") {
  //       fetchRoutes().then(res => {
  //         url = res.routes.find(r => r.component_name==last && store.state.currentLang==r.lang && r.nid==lastR)
  //         if(url) next(url.path)
  //         else next(false)
  //       })

  //     } else if(last && last!="Home" && last!="details/Item") {
  //       console.log('not home', last, to.name)
  //       fetchRoutes().then(res => {
  //         url = res.routes.find(r => r.component_name==last && store.state.currentLang==r.lang)
  //         if(url) next(url.path)
  //         else next(false)
  //       })
        
  //     } else if(last && last=="Home") {
  //       next('/' + ((store.state.currentLang==store.state.defaultLang) ? '' : store.state.currentLang))

  //     } else
  //       next(false)

  //   } else next()

  //   if(from.name!=to.name) {
  //     if(from.name==null) {
  //       localStorage.setItem('LAST_VISITED', to.name)
  //       console.log('imposta nuova', localStorage.getItem('LAST_VISITED'))
  //     }
  //     else if(from.name=="details/Item") {
  //       localStorage.setItem('LAST_VISITED', from.name)
  //       localStorage.setItem('LAST_REPERTO', from.params.nid)
  //       console.log('vecchio reperto', localStorage.getItem('LAST_REPERTO'))
  //     } else {
  //       localStorage.setItem('LAST_VISITED', from.name)
  //       console.log('pagina diversa', localStorage.getItem('LAST_VISITED'))
  //     }
  //   } else if(from.name=="details/Item" && to.name=="details/Item" && from.params.nid!=to.params.nid) {
  //     localStorage.setItem('LAST_VISITED', from.name)
  //     localStorage.setItem('LAST_REPERTO', from.params.nid)
  //     console.log('cambio reperto', localStorage.getItem('LAST_VISITED'), localStorage.getItem('LAST_REPERTO'))
  //   } else {
  //     console.log('stessa pagina', from.name, to.name, localStorage.getItem('LAST_VISITED'))
  //   }
    
  // })

  router.afterEach((to) => {
    //console.log('check lingua')
    //language come impostato nello STORE
    var lfs = store.state.currentLang

    //language come selezionato dall'URL PATH
    var lfp = to.fullPath.substring(1, 4)

    //controlla i primi due caratteri dell'url
    //se sono contenuti nell'array delle lingue disponibili + sono seguiti da un '/' o da nessun carattere
    if(store.state.languages.includes(lfp.substring(0, 2)) && (lfp[2]=='/' || lfp[2]==undefined)) {

      //allora è stata selezionata una nuova lingua direttamente dall'url
      //impostazione diretta della lingua (es: quando si arriva da un link condiviso)
      //console.log('SET CURRENT LANGUAGE', lfp.substring(0, 2))
      store.commit('SET_CURRENT_LANG', lfp.substring(0, 2))

    } else {
      //altrimenti significa che i caratteri seguenti il dominio definiscono direttamente una route
      //console.log('SET DEFAULT LANGUAGE', lfs)
      store.commit('SET_CURRENT_LANG', lfs)
    }
  })

  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app")

})